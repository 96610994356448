export default {
  PERM_VIEW_MODULE_PAYMENTS: "Access the payments module",
  PERM_VIEW_ANY_PAYMENTS: "See all payments",
  PERM_VIEW_PAYMENTS: "See payment details",
  PERM_CREATE_PAYMENTS: "Create a payment",
  PERM_VIEW_MODULE_SUBSCRIPTIONS: "Access the subscriptions module",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "See all subscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "See subscription details",
  PERM_CREATE_SUBSCRIPTIONS: "Create a subscription",
  PERM_EDIT_SUBSCRIPTIONS: "Edit subscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "Delete a subscription",
  PERM_VIEW_MODULE_PACKAGES: "Access the packages module",
  PERM_VIEW_ANY_PACKAGES: "See all packages",
  PERM_VIEW_PACKAGES: "See package details",
  PERM_CREATE_PACKAGES: "Create a package",
  PERM_EDIT_PACKAGES: "Edit packages",
  PERM_DELETE_PACKAGES: "Delete a package",
  PERM_VIEW_MODULE_CUSTOMERS: "Access the customers module",
  PERM_VIEW_ANY_CUSTOMERS: "See all customers",
  PERM_VIEW_CUSTOMERS: "See customer details",
  PERM_CREATE_CUSTOMERS: "Create a customer",
  PERM_EDIT_CUSTOMERS: "Edit customers",
  PERM_DELETE_CUSTOMERS: "Delete a customer",
  PERM_VIEW_MODULE_INVOICES: "Access the invoices module",
  PERM_VIEW_ANY_INVOICES: "See all invoices",
  PERM_VIEW_INVOICES: "See invoice details",
  PERM_CREATE_INVOICES: "Create an invoice",
  PERM_EDIT_INVOICES: "Edit invoices",
  PERM_DELETE_INVOICES: "Delete an invoice",
  PERM_VIEW_MODULE_PRODUCTS: "Access the products module",
  PERM_VIEW_ANY_PRODUCTS: "See all products",
  PERM_VIEW_PRODUCTS: "See product details",
  PERM_CREATE_PRODUCTS: "Create a product",
  PERM_EDIT_PRODUCTS: "Edit products",
  PERM_EDIT_OWN_PRODUCTS: "Edit own product",
  PERM_DELETE_PRODUCTS: "Delete products",
  PERM_VIEW_MODULE_ORGANIZATIONS: "Access the organizations module",
  PERM_VIEW_ANY_ORGANIZATIONS: "See all organizations",
  PERM_VIEW_ORGANIZATIONS: "See organization details",
  PERM_CREATE_ORGANIZATIONS: "Create an organization",
  PERM_EDIT_ORGANIZATIONS: "Edit organizations",
  PERM_EDIT_OWN_ORGANIZATIONS: "Edit own organization",
  PERM_DELETE_ORGANIZATIONS: "Delete organizations",
  PERM_VIEW_MODULE_RESELLERS: "Access the resellers module",
  PERM_VIEW_ANY_RESELLERS: "View all resellers",
  PERM_VIEW_RESELLERS: "View resellers details",
  PERM_CREATE_RESELLERS: "Create a reseller",
  PERM_EDIT_RESELLERS: "Edit resellers",
  PERM_EDIT_OWN_RESELLERS: "Edit own reseller",
  PERM_DELETE_RESELLERS: "Delete resellers",
  PERM_VIEW_MODULE_USERS: "Access the users module",
  PERM_VIEW_ANY_USERS: "View all users",
  PERM_VIEW_USERS: "View users details",
  PERM_CREATE_USERS: "Create a user",
  PERM_EDIT_USERS: "Edit users",
  PERM_DELETE_USERS: "Delete users",
  PERM_VIEW_MODULE_ROLES: "Access the roles module",
  PERM_VIEW_ANY_ROLES: "View all roles",
  PERM_VIEW_ROLES: "View roles details",
  PERM_CREATE_ROLES: "Create a role",
  PERM_EDIT_ROLES: "Edit roles",
  PERM_DELETE_ROLES: "Delete roles",
  PERM_VIEW_ANY_PERMISSIONS: "View all permissions",
  PERM_VIEW_PERMISSIONS: "View permissions details",
  PERM_VIEW_ANY_LOGS: "View all logs",
  PERM_VIEW_LOGS: "View log details",
  PERM_EDIT_ORGANIZATION_TEMPLATES: "Edit organization content templates",
  PERM_VIEW_MODULE_TEMPLATES_MAILS: "Accss the mail template module",
  PERM_VIEW_ANY_TEMPLATES_MAILS: "View any templates",
  PERM_VIEW_TEMPLATES_MAILS: "View mail template",
  PERM_CREATE_TEMPLATES_MAILS: "Create mail template",
  PERM_EDIT_TEMPLATES_MAILS: "Edit mail template",
  PERM_DELETE_TEMPLATES_MAILS: "Delete mail template",
  PERM_VIEW_MODULE_LABELS: "view module labels",
  PERM_VIEW_ANY_LABELS: "view any labels",
  PERM_VIEW_LABELS: "view label",
  PERM_CREATE_LABELS: "create label",
  PERM_EDIT_LABELS: "edit label",
  PERM_DELETE_LABELS: "delete label",
};
