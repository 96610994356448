export default {
  ORGANIZATIONS_LIST: "Organization List",
  ADD_ORGANIZATION: "Add an Organization",
  ORGANIZATION_ADDED: "Organization Added",
  OWNER: "Owner",
  EDIT_ORGANIZATION: "Edit an Organization",
  ORGANIZATION_UPDATED: "Organization Updated",
  SELECT_A_PACKAGE: "Select a Package",
  ADD_SUBSCRIPTION: "Add a Subscription",
  SUBSCRIPTION_INVOICE_ADDED: "Subscription Invoice Added",
  AUTHORIZATIONS: "Authorizations",
  DELETE_THIS_ORGANIZATION: "Delete this Organization?",
  ORGANIZATION_DELETED: "Organization Deleted",
  TEMPLATE_BLOCK_LETTER_INTRO : "Lettre certifiée - Bloc Introduction",
  TEMPLATE_BLOCK_LETTER_SELL_NOTICE : "Lettre certifiée - Bloc Notification de vente",
  TEMPLATE_BLOCK_LETTER_PAYMENT_TERMS : "Lettre certifiée - Bloc Conditions de paiement",
  TEMPLATE_BLOCK_LETTER_OPEN_HOURS : "Lettre certifiée - Bloc Heures d'ouverture",
  TEMPLATE_BLOCK_LETTER_IMPORTANT_NOTE : "Lettre certifiée - Bloc Note importante",
  TEMPLATE_BLOCK_LETTER_SENDER : "Lettre certifiée - Bloc nom de l'expéditeur",
  TEMPLATE_BLOCK_LETTER_SENDERS_ROLE : "Lettre certifiée - Bloc Fonction de l'expéditeur",
  TEMPLATE_BLOCK_LETTER_AFTER_TAXES_FEES_TABLE : "Lettre certifiée - Bloc Après le tableau des taxes et frais",
  TEMPLATE_BLOCK_WEBSITE_ABOUT : "Site WEB - Bloc À propos de l'organisation",
  TEMPLATE_BLOCK_WEBSITE_SALE_CONDITIONS : "Site WEB - Bloc Règlement ou condition de vente",
  TEMPLATE_BLOCK_PUBLIC_NOTICE_HEADER : "Avis Public - Bloc en-tête",
  TEMPLATE_BLOCK_PUBLIC_NOTICE_FOOTER : "Avis Public - Bloc pied de page",
  TEMPLATE_BLOCK_UNSOLD_PROPERTIES_HEADER : "Propriétés non vendues - Bloc en-tête",
  TEMPLATE_BLOCK_UNSOLD_PROPERTIES_SIGNATURE : "Propriétés non vendues - Bloc signature",
  TEMPLATE_BLOCK_UNSOLD_PROPERTIES_SENDER : "Propriétés non vendues - Bloc nom de l'expéditeur",
  TEMPLATE_BLOCK_UNSOLD_PROPERTIES_SENDER_ROLE : "Propriétés non vendues - Bloc Fonction de l'expéditeur",
  TEMPLATE_BLOCK_CERTIFICATES_AWARDS_SUBJECT : "Certificat d'adjudication - Bloc Objet",
  TEMPLATE_BLOCK_CERTIFICATES_AWARDS_MESSAGE : "Certificat d'adjudication - Bloc Message",
  TEMPLATE_BLOCK_CERTIFICATES_AWARDS_SIGNATURE : "Certificat d'adjudication - Bloc Signature",
  TEMPLATE_BLOCK_CERTIFICATES_AWARDS_DELIVERED_AT : "Certificat d'adjudication - Bloc livraison",
  TEMPLATE_BLOCK_CERTIFICATES_AWARDS_DESCRIPTION_ADDRESS : "Certificat d'adjudication - Bloc description et adresse",
  TEMPLATE_BLOCK_CERTIFICATES_AWARDS_AMOUNT_TAXES_TOTAL : "Certificat d'adjudication - Bloc montant, taxes et total",
  TEMPLATE_BLOCK_CERTIFICATES_AWARDS_OWNER_INFOS : "Certificat d'adjudication - Bloc Infos proprétaire",
};
