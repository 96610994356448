import {
  SOURCE_SOURCE_BANK_DRAFT,
  SOURCE_CERTIFIED_CHECK,
  SOURCE_POST_ORDER,
  SOURCE_CASH,
  SOURCE_BANK_TRANSFER,
  SOURCE_NORMAL_CHECK,
  SOURCE_FIDEOCOMMIS_CHECK,
  SOURCE_PAYMENT_SOURCE_NORMAL_CHECK,
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_COMPLETED,
  PAYMENT_STATUS_CANCELED,
} from "../../constants/propertiesPayment";
export default {
  PROPERTIES_PAYMENTS_LIST: "Payments list",
  ADD_PROPERTIES_PAYMENT: "Add un paiement",
  PROPERTIES_PAYMENT_ADDED: "Payment Added",
  EDIT_PROPERTIES_PAYMENT: "Edit payment",
  PROPERTIES_PAYMENT_UPDATED: "Payment updated",
  PROPERTY: "Property",
  AMOUNT: "Amount",
  SOURCE: "Transaction method",
  DATE: "Payment date",
  ATTENTION : "Attention",
  EXCERPT: "Excerpt",
  VIEW_PROPERTIES_PAYMENT: "Payment",
  DELETE_THIS_PROPERTIES_PAYMENT: "Delete this paiement ?",
  PROPERTIES_PAYMENT_DELETED: "Paiement deleted",
  PAYMENT_SOURCE_BANK_DRAFT: "Traite bancaire",
  PAYMENT_SOURCE_CERTIFIED_CHECK: "Chèque certifié",
  PAYMENT_SOURCE_POST_ORDER: "Mandat poste",
  PAYMENT_SOURCE_CASH: "Argent comptant",
  PAYMENT_SOURCE_BANK_TRANSFER: "Transfert bancaire",
  PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normale",
  PAYMENT_SOURCE_FIDEOCOMMIS_CHECK: "Chèque fidéocommis",
  PAYMENT_SOURCE_PAYMENT_SOURCE_NORMAL_CHECK: "Chèque normal",
  PAYMENT_STATUS_DRAFT: "Draft",
  PAYMENT_STATUS_COMPLETED: "Completed",
  PAYMENT_STATUS_CANCELED: "Canceled",
  PAYMENT_AMOUNT_GRATER_TO_SUM: "Le montant saisi est supérieur au total du montant des propriétés : ",
  PAYMENT_REFUND_TEXT: "ou de l'ajouter dans le montant de la dérinière propriété pour qu'il sera rembourser après",
  PAYMENT_ANOTHER_PROPERTY_TEXT: "Vous pouvez soit payer une autre propriété",
  PAYMENT_REFUND_BTN: "Ajouter à la dernière propriété",
  PAYMENT_ANOTHER_PROPERTY_BTN: "Payer une autre propriété",
};
